// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-education-tsx": () => import("./../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-experiences-tsx": () => import("./../src/pages/experiences.tsx" /* webpackChunkName: "component---src-pages-experiences-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-site-info-tsx": () => import("./../src/pages/site-info.tsx" /* webpackChunkName: "component---src-pages-site-info-tsx" */),
  "component---src-pages-work-tsx": () => import("./../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

